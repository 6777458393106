<style>
.searched {
	background-color: #00a09d57;
}
</style>

<template>
	<div v-if="budget.data">
		<b-row style="border: 1px solid #dfdfdf !important; margin: 0" :class="{ searched: budget.is_selected }">
			<b-col cols="2" style="display: flex; align-items: center">
				<span style="display: inline-block" col="2" :style="{ marginLeft: nodeMargin + 'px' }" class="mr-2">
					<SvgPlus v-if="!showChildren" :style="{ visibility: budget.children ? 'visible' : 'hidden' }" @clicked="toggleChildren" />
					<SvgMinus v-if="showChildren" :style="{ visibility: budget.children ? 'visible' : 'hidden' }" @clicked="toggleChildren" />
				</span>
				<div col="10" style="border: 0px !important" class="border-0 px-1 py-0">
					<b-form-input
						:disabled="budget.data.category == 0"
						v-model="budget.data.costCenter"
						type="text"
						@change="updatedCostCenter(budget.data)"
						id="costCenterT"
					></b-form-input>
				</div>
			</b-col>
			<b-col cols="2" style="display: flex; align-items: center" class="px-1">
				<b-form-input
					v-model="budget.data.description"
					:disabled="(budget.data.contentType == 1 && budget.data.isSum && budget.data.parentId != 0) || budget.data.category == 0"
					type="text"
					@change="updatedCostCenter(budget.data)"
					id="costCenterT"
				></b-form-input>
			</b-col>
			<b-col cols="1" style="display: flex; align-items: center" class="px-1 py-0 text-center">
				<b-form-select
					v-model="budget.data.contentType"
					:disabled="budget.data.category == 0"
					:options="contentTypeMenu"
					@change="updatedContentTypeAndCoTwo(budget.data)"
				/>
			</b-col>
			<b-col style="display: flex; align-items: center" :cols="`${showCostCenterA || showCostCenterB || showCostCenterC ? 1 : 2}`" class="px-1">
				<b-form-select
					v-if="budget.data.contentType != 1"
					@change="updatedContentTypeAndCoTwo(budget.data)"
					v-model="budget.data.coTwoCategory"
					:options="co2CategoriesMenu"
					:disabled="budget.data.category == 0"
				/>
			</b-col>
			<b-col style="display: flex; align-items: center" v-if="showCostCenterA" class="px-1">
				<b-form-input
					:disabled="budget.data.category == 0"
					v-model="budget.data.costCenterA"
					type="text"
					@change="updatedCostCenter(budget.data)"
					id="costCenterTA"
				></b-form-input>
			</b-col>
			<b-col style="display: flex; align-items: center" v-if="showCostCenterB" class="px-1">
				<b-form-input
					:disabled="budget.data.category == 0"
					v-model="budget.data.costCenterB"
					type="text"
					@change="updatedCostCenter(budget.data)"
					id="costCenterTB"
				></b-form-input>
			</b-col>
			<b-col style="display: flex; align-items: center" v-if="showCostCenterC" class="px-1">
				<b-form-input
					:disabled="budget.data.category == 0"
					v-model="budget.data.costCenterC"
					type="text"
					@change="updatedCostCenter(budget.data)"
					id="costCenterTC"
				></b-form-input>
			</b-col>
			<b-col style="display: flex; align-items: center; justify-content: center" cols="1" class="px-1 py-0 text-center">
				<b-form-checkbox
					v-if="budget.data.contentType != 1"
					style="border: none !important"
					size="lg"
					@change="updateappChoice(budget.data)"
					:disabled="budget.data.isSum || budget.data.category == 0"
					v-model="budget.data.appChoice"
					class="pj-cb"
				></b-form-checkbox>
				<!-- <b-button
					v-if="budget.data.contentType != 1"
					@click="updateappChoice(budget.data)"
					:disabled="budget.data.isSum || budget.data.category == 0"
					style="margin-top: 6px; padding: 4px 5px"
					label-for="visInApp1"
					:variant="`${budget.data.appChoice ? 'primary' : 'outline-danger'}`"
					size="sm"
				>
					<check v-if="budget.data.appChoice" :size="15" />
					<deny v-else :size="15" />
				</b-button> -->
			</b-col>
			<b-col style="display: flex; align-items: center" class="px-1">
				<VueNumberFormat
					v-if="isEnglish"
					v-model="budget.data.initialBudget"
					:options="{ precision: 2, prefix: '', isInteger: false, decimal: '.', thousand: ',' }"
					@update:value="updatedData(budget.data, $event, true)"
					:disabled="budget.data.isSum || isLocked || budget.data.category == 0"
					class="form-control text-right"
				></VueNumberFormat>
				<VueNumberFormat
					v-else
					v-model="budget.data.initialBudget"
					:options="{ precision: 2, prefix: '', isInteger: false, decimal: ',', thousand: '.' }"
					@update:value="updatedData(budget.data, $event, true)"
					:disabled="budget.data.isSum || isLocked || budget.data.category == 0"
					class="form-control text-right"
				></VueNumberFormat>
			</b-col>
			<b-col style="display: flex; align-items: center" class="px-1">
				<VueNumberFormat
					v-if="isEnglish"
					v-model="budget.data.estimatedFinalCost"
					:options="{ precision: 2, prefix: '', isInteger: false, decimal: '.', thousand: ',' }"
					@update:value="updatedData(budget.data, $event, false)"
					:disabled="budget.data.isSum || budget.data.category == 0"
					class="form-control text-right"
				></VueNumberFormat>
				<VueNumberFormat
					v-else
					v-model="budget.data.estimatedFinalCost"
					:options="{ precision: 2, prefix: '', isInteger: false, decimal: ',', thousand: '.' }"
					@update:value="updatedData(budget.data, $event, false)"
					:disabled="budget.data.isSum || budget.data.category == 0"
					class="form-control text-right"
				></VueNumberFormat>
			</b-col>
			<b-col style="display: flex; align-items: center" class="px-1" :cols="1">
				{{ budget.data.departmentName }}
			</b-col>
			<b-col style="display: flex; align-items: center; justify-content: center" class="px-1 text-center">
				<b-dropdown no-caret dropleft offset="25" variant="none" style="border-right: 0px !important; padding-top: 0px !important">
					<template #button-content>
						<more-vertical v-if="budget.data.category != 0" />
					</template>
					<b-dropdown-item-button @click="addChild(budget)"
						><plus-circle /> &nbsp; <b>{{ FormMSG(2000, 'Create a sub category') }}</b></b-dropdown-item-button
					>
					<b-dropdown-item-button @click="clickedRow(budget.data)"
						><edit /> &nbsp; <b>{{ FormMSG(2001, 'Additional settings') }}</b></b-dropdown-item-button
					>
					<b-dropdown-item-button
						v-if="
							(budget.data.IsLinkedToFunction == false && budget.data.isLinkedToDepartment == false) ||
							(budget.data.IsLinkedToFunction == true && budget.data.isLinkedToDepartment == false)
						"
						@click="cloneCat(budget.data)"
						><copy /> &nbsp; <b>{{ FormMSG(2002, 'Duplicate') }}</b></b-dropdown-item-button
					>
					<b-dropdown-item-button
						v-if="
							(budget.data.IsLinkedToFunction == false && budget.data.isLinkedToDepartment == false) ||
							(budget.data.IsLinkedToFunction == true && budget.data.isLinkedToDepartment == false)
						"
						@click="delItemConfirm(budget)"
						><trash2 /> &nbsp; <b>{{ FormMSG(2003, 'Remove') }}</b></b-dropdown-item-button
					>
				</b-dropdown>
			</b-col>
		</b-row>
		<div v-if="hasChildren && showChildren">
			<tree-node-budget
				:childLevel="childLevel + 1"
				:displayAllCategories="displayAllCategories"
				:showCostCenterA="showCostCenterA"
				:showCostCenterB="showCostCenterB"
				:showCostCenterC="showCostCenterC"
				:showchildrenOnSearch="showchildrenOnSearch"
				:depNumber="depNumber"
				v-for="child in budget.children"
				:key="child.id"
				:budgete="child"
				:spacing="spacing + 20"
				@categorie-tree-view:addChild="addChild"
				@categorie-tree-view:delete="deleteChild"
				@categorie-tree-view:select="selectChild"
				@categorie-tree-view:SpecialUpdate="updatedContentTypeAndCoTwo"
				@categorie-tree-view:update="updatedCostCenter"
				@categorie-tree-view:clone="cloneCat"
				@categorie-tree-view:AmountUpdate="updatedChildData"
				@categorie-tree-view:setAsFunc="setAsFunc"
				@categorie-tree-view:setAsDep="setAsDep"
			/>
		</div>
	</div>
</template>

<script>
import SvgPlus from '@/components/Offers/SvgPlus';
import SvgMinus from '@/components/Offers/SvgMinus';
import { rendCurrency, rendKgCo2 } from '~helpers';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import languageMessages from '@/mixins/languageMessages';
import { MoreVertical, Edit, Copy, PlusCircle, Trash2, Check, X as Deny, FolderPlus, FilePlus2 as Function } from 'lucide-vue';
import { isNil } from '@/shared/utils';
import VueNumberFormat from '@igortrindade/vue-number-format';
import { store } from '@/store';

export default {
	name: 'TreeNodeBudget',

	components: {
		SvgPlus,
		SvgMinus,
		MoreVertical,
		Edit,
		Copy,
		PlusCircle,
		Trash2,
		Check,
		Deny,
		VueNumberFormat,
		FolderPlus,
		Function
	},
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	props: {
		childLvlUp: { type: Boolean, default: false },
		budgete: { type: Object, required: true },
		spacing: { type: Number, default: 0 },
		childLevel: { type: Number, default: 0 },
		showCostCenterA: { type: Boolean, default: false },
		showCostCenterB: { type: Boolean, default: false },
		showCostCenterC: { type: Boolean, default: false },
		showchildrenOnSearch: { type: Boolean, default: false },
		depNumber: { type: Number, default: null },
		displayAllCategories: { type: Boolean, default: false }
	},

	data() {
		return {
			showChildren: false,
			hasChildren: true,
			budget: {
				type: Object
			},
			initNumber: '0',
			contentType: false,
			visible: false,
			disable: false
		};
	},
	computed: {
		isLocked() {
			return store.state.myProject.budgetLocked;
		},
		isEnglish() {
			return store.appLanguage() == 0;
		},
		contentTypeMenu() {
			return [
				{
					value: 0,
					text: this.FormMSG(1000, 'Expense')
				},
				{
					value: 1,
					text: this.FormMSG(1001, 'Salary')
				}
			];
		},
		co2CategoriesMenu() {
			return this.FormMenu(1334);
		},
		nodeMargin() {
			return this.spacing;
		},
		childLeveling() {
			return this.childLevel;
		}
	},
	watch: {
		budgete: {
			handler(val) {
				if (!isNil(val) && !isNil(val.data)) {
					this.budget = val;
					this.showChildren = val.is_selected;
					if (val.data.contentType == 0) {
						this.contentType = false;
					} else {
						this.contentType = true;
					}
				}
			},
			immediate: true
		}
	},
	methods: {
		setAsFunc(data) {
			this.$emit('categorie-tree-view:setAsFunc', data);
		},
		setAsDep(data) {
			this.$emit('categorie-tree-view:setAsDep', data);
		},
		updatedChildData(data) {
			this.$emit('categorie-tree-view:AmountUpdate', data);
		},
		updatedData(data, evt, initialBudget) {
			if (initialBudget) {
				if (data.initialBudget === data.estimatedFinalCost) {
					data.initialBudget = evt;
					data.estimatedFinalCost = evt;
				} else {
					data.initialBudget = evt;
				}
			} else {
				data.estimatedFinalCost = evt;
			}
			this.$emit('categorie-tree-view:AmountUpdate', data);
		},
		cloneCat(data) {
			this.$emit('categorie-tree-view:clone', data);
		},
		updateappChoice(data) {
			// data.appChoice = !data.appChoice;
			this.updatedCostCenter(data);
		},
		addChild(data) {
			this.showChildren = true;
			this.$emit('categorie-tree-view:addChild', data);
		},
		updatedContentTypeAndCoTwo(data) {
			if (data.isSum == true) {
				this.$emit('categorie-tree-view:SpecialUpdate', data);
			} else {
				this.$emit('categorie-tree-view:update', data);
			}
		},
		updatedCostCenter(data) {
			this.$emit('categorie-tree-view:update', data);
		},
		selectChild(payload) {
			this.$emit('categorie-tree-view:select', payload);
		},
		deleteChild(payload) {
			this.$emit('categorie-tree-view:delete', payload);
		},
		clickedRow(data) {
			this.$emit('categorie-tree-view:select', data);
		},
		delItemConfirm(id) {
			this.$emit('categorie-tree-view:delete', id);
		},
		async toggleChildren() {
			this.showChildren = !this.showChildren;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		}
	}
};
</script>
