import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const updateDepartments = async (DepartmentId, DepartmentName) => {
	try {
		const mutation = gql`
			mutation ($DepartmentId: ID!, $DepartmentName: String!) {
				UpdDepartment(DepartmentId: $DepartmentId, DepartmentName: $DepartmentName) {
					id
					value
					message
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				DepartmentId,
				DepartmentName
			}
		});

		return data.UpdDepartment;
	} catch (e) {
		console.log({ e });
	}
};

export const updateFunction = async (FunctionId, FunctionName) => {
	try {
		const mutation = gql`
			mutation ($FunctionId: ID!, $FunctionName: String!) {
				UpdFunction(FunctionId: $FunctionId, FunctionName: $FunctionName) {
					id
					value
					departmentNumber
					message
					costCenter
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				FunctionId,
				FunctionName
			}
		});

		return data.UpdFunction;
	} catch (e) {
		console.log({ e });
	}
};
