var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c("loading", {
            attrs: { active: _vm.isLoading, "is-full-page": true },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _vm.Deps
            ? _c(
                "b-row",
                { staticClass: "container-layout" },
                [
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "card card-border-blue-light" },
                      [
                        _c(
                          "b-form",
                          [
                            _c(
                              "b-row",
                              [
                                !_vm.isFilmSingle
                                  ? _c("b-col", { attrs: { md: "12" } }, [
                                      _c("h6", [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                2303,
                                                "Department selection:"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ])
                                  : _vm._e(),
                                !_vm.isFilmSingle
                                  ? _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("b-form-select", {
                                              attrs: {
                                                disabled:
                                                  _vm.isFilmSingle ||
                                                  _vm.isFilmSingleFree,
                                                options: _vm.Deps,
                                                id: "depChoice",
                                                size: "md",
                                              },
                                              on: { change: _vm.depChanged },
                                              model: {
                                                value: _vm.curDep.value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curDep,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "curDep.value",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _vm.$screen.width > 992
                                      ? _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "search",
                                                id: "filterInput",
                                                placeholder: this.FormMSG(
                                                  37,
                                                  "Type to Search"
                                                ),
                                              },
                                              on: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.setSearch(
                                                    _vm.filter
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.filter,
                                                callback: function ($$v) {
                                                  _vm.filter = $$v
                                                },
                                                expression: "filter",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    on: {
                                                      click: _vm.setSearch,
                                                    },
                                                  },
                                                  [
                                                    _c("search", {
                                                      attrs: {
                                                        width: "20",
                                                        height: "20",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    !_vm.isFilmSingle
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              block: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.createNewCats()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  34,
                                                  "Create a new category for"
                                                )
                                              ) +
                                                " " +
                                                _vm._s(_vm.curDepMessage)
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              block: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.createNewCats()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  340,
                                                  "Create a new category"
                                                )
                                              ) + "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.isFilmSingle && !_vm.isFilmSingleFree
                              ? _c(
                                  "b-row",
                                  { staticClass: "pb-1 pt-3 pl-4" },
                                  [
                                    _c(
                                      "b-form-group",
                                      { staticClass: "mr-3" },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb",
                                            attrs: { size: "lg", id: "disAll" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.displayAllCats()
                                              },
                                            },
                                            model: {
                                              value: _vm.displayAllCategories,
                                              callback: function ($$v) {
                                                _vm.displayAllCategories = $$v
                                              },
                                              expression:
                                                "displayAllCategories",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(
                                                      24,
                                                      "Display all"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "mr-3" },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb",
                                            attrs: { size: "lg", id: "Cc1" },
                                            model: {
                                              value: _vm.showCostCenterA,
                                              callback: function ($$v) {
                                                _vm.showCostCenterA = $$v
                                              },
                                              expression: "showCostCenterA",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(
                                                      2400,
                                                      "Show Cost Center A"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      { staticClass: "mr-3" },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb",
                                            attrs: { size: "lg", id: "Cc2" },
                                            model: {
                                              value: _vm.showCostCenterB,
                                              callback: function ($$v) {
                                                _vm.showCostCenterB = $$v
                                              },
                                              expression: "showCostCenterB",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(
                                                      2401,
                                                      "Show Cost Center B"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb",
                                            attrs: { size: "lg", id: "Cc3" },
                                            model: {
                                              value: _vm.showCostCenterC,
                                              callback: function ($$v) {
                                                _vm.showCostCenterC = $$v
                                              },
                                              expression: "showCostCenterC",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "1px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.FormMSG(
                                                      2402,
                                                      "Show Cost Center C"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.generateCarbon()
                                              },
                                            },
                                          },
                                          [
                                            _vm.carbonInProccess
                                              ? _c("b-spinner", {
                                                  attrs: { small: "" },
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    546513,
                                                    "Generate forecast carbon entry based on Budget"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", xl: "12" } },
                                  [
                                    _vm.$screen.width < 992
                                      ? _c("CardListBuilder", {
                                          attrs: {
                                            items: _vm.Cats,
                                            fields: _vm.catMobileFields,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "primary",
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.rowClickedMobile(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-eye",
                                                        }),
                                                      ]
                                                    ),
                                                    data.item.contentType ===
                                                      0 &&
                                                    data.item.category !== 3 &&
                                                    data.item.category !== 4 &&
                                                    data.item.category !== 9 &&
                                                    (data.item.department ===
                                                      0 ||
                                                      data.item.type === 1)
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant: "danger",
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delItemConfirm(
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon-trash",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2800109868
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm.$screen.width > 992
                                      ? _c("TableBudgetCategory", {
                                          attrs: {
                                            categorie: _vm.perCats,
                                            isLoading: _vm.isLoading,
                                            displayAllCategories:
                                              _vm.displayAllCategories,
                                            filter: _vm.searchData,
                                            depNumber: _vm.curDep.value,
                                            showCostCenterA:
                                              _vm.showCostCenterA,
                                            showCostCenterB:
                                              _vm.showCostCenterB,
                                            showCostCenterC:
                                              _vm.showCostCenterC,
                                          },
                                          on: {
                                            "categorie-tree-view:select":
                                              _vm.SelectedCategorie,
                                            "categorie-tree-view:delete":
                                              _vm.delItemConfirm,
                                            "categorie-tree-view:update":
                                              _vm.updatedCostCenter,
                                            "categorie-tree-view:addChild":
                                              _vm.addChild,
                                            "categorie-tree-view:clone":
                                              _vm.cloneCats,
                                            "categorie-tree-view:SpecialUpdate":
                                              _vm.updatedContentTypeAndCoTwo,
                                            "categorie-tree-view:AmountUpdate":
                                              _vm.updateAmount,
                                            "categorie-tree-view:setAsDep":
                                              _vm.setAsDep,
                                            "categorie-tree-view:setAsFunc":
                                              _vm.setAsFunc,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        centered: "",
                        size: "xl",
                        "cancel-variant": "outline-primary",
                        title: _vm.FormMSG(
                          2004,
                          "Budget category advance settings"
                        ),
                        "button-size": "lg",
                        "ok-title": _vm.FormMSG(201, "Save"),
                        "cancel-title": _vm.FormMSG(246, "Cancel"),
                        "no-close-on-backdrop": true,
                      },
                      on: {
                        ok: function ($event) {
                          return _vm.handleUpd()
                        },
                        cancel: function ($event) {
                          return _vm.deleteCatsInModal(_vm.newCat)
                        },
                      },
                      model: {
                        value: _vm.editModal,
                        callback: function ($$v) {
                          _vm.editModal = $$v
                        },
                        expression: "editModal",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "fontBold",
                                      attrs: {
                                        label: this.FormMSG(
                                          2500,
                                          "Category name:"
                                        ),
                                        "label-for": "catName",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          disabled:
                                            _vm.newCat.isLinkedToDepartment ||
                                            _vm.newCat.IsLinkedToFunction,
                                          type: "text",
                                          id: "catName",
                                        },
                                        model: {
                                          value: _vm.newCat.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newCat,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "newCat.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.curDep.value != 0
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "4", sm: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              251,
                                              "Category type:"
                                            ),
                                            "label-for": "catName",
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              disabled:
                                                _vm.newCat
                                                  .isLinkedToDepartment ||
                                                _vm.newCat.IsLinkedToFunction,
                                              options: _vm.contentTypeMenu,
                                            },
                                            model: {
                                              value: _vm.newCat.contentType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "contentType",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.contentType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-col",
                                { attrs: { md: "4", sm: "12" } },
                                [
                                  !_vm.isFilmSingle
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              2300,
                                              "Department:"
                                            ),
                                            "label-for": "depChoice",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              disabled: "",
                                              id: "depChoice",
                                              size: "md",
                                            },
                                            model: {
                                              value: _vm.newCat.departmentName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "departmentName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newCat.departmentName",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "fontBold",
                                      attrs: {
                                        label: _vm.FormMSG(
                                          2544,
                                          "Budget amount"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              disabled:
                                                _vm.newCat.isSum ||
                                                _vm.isLocked,
                                              type: "number",
                                              id: "spent",
                                            },
                                            on: { change: _vm.handleNewValue },
                                            model: {
                                              value: _vm.newCat.initialBudget,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "initialBudget",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newCat.initialBudget",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-input-group-text",
                                                [
                                                  _c("currency-svg", {
                                                    attrs: {
                                                      color: "#06263E",
                                                      opacity: "0.85",
                                                      width: "15",
                                                      height: "15",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4", sm: "12" } },
                                [
                                  !_vm.isFilmSingle
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: _vm.FormMSG(2545, "EFC"),
                                            "label-for": "spent",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  disabled:
                                                    _vm.newCat.isSum ||
                                                    _vm.isEditable,
                                                  type: "number",
                                                  id: "spent",
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleEstimateChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.newCat
                                                      .estimatedFinalCost,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newCat,
                                                      "estimatedFinalCost",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newCat.estimatedFinalCost",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#06263E",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "fontBold",
                                      attrs: {
                                        label: _vm.FormMSG(
                                          2546,
                                          "Initial spent"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              disabled: _vm.newCat.isSum,
                                              type: "number",
                                              id: "spent",
                                            },
                                            model: {
                                              value:
                                                _vm.newCat.startOfPeriodValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "startOfPeriodValue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newCat.startOfPeriodValue",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-input-group-text",
                                                [
                                                  _c("currency-svg", {
                                                    attrs: {
                                                      color: "#06263E",
                                                      opacity: "0.85",
                                                      width: "15",
                                                      height: "15",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pt-28",
                                  attrs: { md: "3", sm: "6" },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        size: "md",
                                        id: "visInApp2",
                                        disabled: "",
                                      },
                                      on: { change: _vm.checkChange },
                                      model: {
                                        value: _vm.newCat.isSum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.newCat, "isSum", $$v)
                                        },
                                        expression: "newCat.isSum",
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "fontBold",
                                          staticStyle: { "margin-top": "2px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                301,
                                                "Grouping Category"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  _vm.newCat.IsLinkedToFunction == false &&
                                  _vm.newCat.isLinkedToDepartment == false
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              250,
                                              "Parent Category:"
                                            ),
                                            "label-for": "catName",
                                          },
                                        },
                                        [
                                          _c("treeselect", {
                                            staticClass: "border",
                                            attrs: {
                                              multiple: false,
                                              options: _vm.treeCatsSelect,
                                            },
                                            on: { change: _vm.parentChange },
                                            model: {
                                              value: _vm.newCat.parentId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "parentId",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.parentId",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              !_vm.newCat.isSum && _vm.newCat.contentType != 1
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pt-28",
                                      attrs: { md: "3", sm: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            size: "md",
                                            id: "visInApp1",
                                          },
                                          model: {
                                            value: _vm.newCat.appChoice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newCat,
                                                "appChoice",
                                                $$v
                                              )
                                            },
                                            expression: "newCat.appChoice",
                                          },
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass: "fontBold",
                                              staticStyle: {
                                                "margin-top": "2px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    30,
                                                    "Visible in App"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  _vm.newCat.appChoice == 1 &&
                                  !_vm.newCat.isSum &&
                                  _vm.newCat.contentType != 1
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              3200,
                                              "Name in App:"
                                            ),
                                            "label-for": "labelInApp",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              id: "labelInApp",
                                            },
                                            model: {
                                              value: _vm.newCat.appName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "appName",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.appName",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "fontBold",
                                      attrs: {
                                        label: this.FormMSG(29, "Cost center:"),
                                        "label-for": "costCenter",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "costCenter",
                                        },
                                        model: {
                                          value: _vm.newCat.costCenter,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newCat,
                                              "costCenter",
                                              $$v
                                            )
                                          },
                                          expression: "newCat.costCenter",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  !_vm.isFilmSingle
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              72,
                                              "Cost center A:"
                                            ),
                                            "label-for": "costCenterA",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              id: "costCenterA",
                                            },
                                            model: {
                                              value: _vm.newCat.costCenterA,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "costCenterA",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.costCenterA",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  !_vm.isFilmSingle
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              73,
                                              "Cost center B:"
                                            ),
                                            "label-for": "costCenterB",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              id: "costCenterB",
                                            },
                                            model: {
                                              value: _vm.newCat.costCenterB,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "costCenterB",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.costCenterB",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  !_vm.isFilmSingle
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              74,
                                              "Cost center C:"
                                            ),
                                            "label-for": "costCenterC",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              id: "costCenterC",
                                            },
                                            model: {
                                              value: _vm.newCat.costCenterC,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "costCenterC",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.costCenterC",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  !_vm.newCat.isSum
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(2700, "VAT:"),
                                            "label-for": "catVatPercent",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  id: "catVatPercent",
                                                },
                                                model: {
                                                  value: _vm.newCat.vatPercent,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newCat,
                                                      "vatPercent",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newCat.vatPercent",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("percent", {
                                                        attrs: {
                                                          color: "#06263E",
                                                          width: "20",
                                                          height: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3", sm: "6" } },
                                [
                                  !_vm.newCat.isSum
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(
                                              2701,
                                              "VAT paid back:"
                                            ),
                                            "label-for": "catVatPercent",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  id: "catVatPercent",
                                                },
                                                model: {
                                                  value: _vm.newCat.vatPaidBack,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newCat,
                                                      "vatPaidBack",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newCat.vatPaidBack",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("percent", {
                                                        attrs: {
                                                          color: "#06263E",
                                                          width: "20",
                                                          height: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _vm.newCat.contentType != 1
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "4", sm: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: _vm.FormMSG(
                                              120,
                                              "CO2 Category:"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.co2CategoriesMenu,
                                            },
                                            model: {
                                              value: _vm.newCat.coTwoCategory,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "coTwoCategory",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newCat.coTwoCategory",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-col",
                                { attrs: { md: "4", sm: "6" } },
                                [
                                  !_vm.newCat.isSum
                                    ? _c(
                                        "b-form-group",
                                        {
                                          staticClass: "fontBold",
                                          attrs: {
                                            label: this.FormMSG(31, "Comment:"),
                                            "label-for": "catcomment",
                                          },
                                        },
                                        [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              type: "textarea",
                                              rows: "2",
                                              "max-rows": "2",
                                              id: "catcomment",
                                              autocomplete: "off",
                                            },
                                            model: {
                                              value: _vm.newCat.comment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newCat,
                                                  "comment",
                                                  $$v
                                                )
                                              },
                                              expression: "newCat.comment",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                this.carbonModel === 2
                                  ? _c(
                                      "div",
                                      [
                                        _c("input-select-doconomy", {
                                          attrs: {
                                            "doconomy-category-params":
                                              _vm.newCat.doconomyCategory,
                                          },
                                          on: {
                                            "select:category":
                                              _vm.updateDoconomyCategory,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _vm.newCat.contentType === 0 &&
                          _vm.isCarbonClap &&
                          (!_vm.newCat.isSum || _vm.newCat.department === 0)
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "content-card-collapsible inside",
                                    },
                                    [
                                      _c(
                                        "fieldset",
                                        { staticClass: "scheduler-border" },
                                        [
                                          _c(
                                            "legend",
                                            { staticClass: "scheduler-border" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      136523,
                                                      "Carbon clap"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex align-items-center py-3",
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass: "pj-cb",
                                                  attrs: {
                                                    size: "lg",
                                                    id: "usedForCC",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newCat
                                                        .usedForCarbonClapForecastReport,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newCat,
                                                        "usedForCarbonClapForecastReport",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newCat.usedForCarbonClapForecastReport",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            136522,
                                                            "Used for Carbon Clap Forecast Report "
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-success",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(38, "Success!"),
                        "ok-variant": "success",
                        "ok-only": "",
                      },
                      on: {
                        ok: function ($event) {
                          _vm.successModal = false
                        },
                      },
                      model: {
                        value: _vm.successModal,
                        callback: function ($$v) {
                          _vm.successModal = $$v
                        },
                        expression: "successModal",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            this.FormMSG(
                              39,
                              "The new department name has been saved."
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-success",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(38, "Success!"),
                        "ok-variant": "success",
                        "ok-only": "",
                      },
                      on: {
                        ok: function ($event) {
                          _vm.successModalDelete = false
                        },
                      },
                      model: {
                        value: _vm.successModalDelete,
                        callback: function ($$v) {
                          _vm.successModalDelete = $$v
                        },
                        expression: "successModalDelete",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            this.FormMSG(
                              40,
                              "Your information has been deleted."
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }