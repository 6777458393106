<template>
	<div>
		<b-row class="header-table-budgets">
			<b-col cols="2" @click="handleClickHeader('costCenter')" class="px-1 d-flex cursor-pointer">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(1100, 'Cost Center') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.costCenter === 0"
							:size="16"
							:color="getColorChevrons('costCenter')"
							:fill="getColorChevrons('costCenter')"
						/>
						<chevron-down
							v-if="sortFilter.costCenter === 1"
							:size="16"
							:color="getColorChevrons('costCenter')"
							:fill="getColorChevrons('costCenter')"
						/>
						<chevron-up
							v-if="sortFilter.costCenter === 2"
							:size="16"
							:color="getColorChevrons('costCenter')"
							:fill="getColorChevrons('costCenter')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="2" @click="handleClickHeader('description')" class="px-1 d-flex cursor-pointer">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(113, 'Name') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.description === 0"
							:size="16"
							:color="getColorChevrons('description')"
							:fill="getColorChevrons('description')"
						/>
						<chevron-down
							v-if="sortFilter.description === 1"
							:size="16"
							:color="getColorChevrons('description')"
							:fill="getColorChevrons('description')"
						/>
						<chevron-up
							v-if="sortFilter.description === 2"
							:size="16"
							:color="getColorChevrons('description')"
							:fill="getColorChevrons('description')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="px-1 d-flex cursor-pointer" @click="handleClickHeader('contentType')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(11213, 'Type') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.contentType === 0"
							:size="16"
							:color="getColorChevrons('contentType')"
							:fill="getColorChevrons('contentType')"
						/>
						<chevron-down
							v-if="sortFilter.contentType === 1"
							:size="16"
							:color="getColorChevrons('contentType')"
							:fill="getColorChevrons('contentType')"
						/>
						<chevron-up
							v-if="sortFilter.contentType === 2"
							:size="16"
							:color="getColorChevrons('contentType')"
							:fill="getColorChevrons('contentType')"
						/>
					</span>
				</div>
			</b-col>
			<b-col
				:cols="`${showCostCenterA || showCostCenterB || showCostCenterC ? 1 : 2}`"
				@click="handleClickHeader('coTwoCategory')"
				class="px-1 d-flex cursor-pointer"
			>
				<div class="w-75" style="font-size: 10px">{{ FormMSG(1112, 'CO2 Category') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.coTwoCategory === 0"
							:size="16"
							:color="getColorChevrons('coTwoCategory')"
							:fill="getColorChevrons('coTwoCategory')"
						/>
						<chevron-down
							v-if="sortFilter.coTwoCategory === 1"
							:size="16"
							:color="getColorChevrons('coTwoCategory')"
							:fill="getColorChevrons('coTwoCategory')"
						/>
						<chevron-up
							v-if="sortFilter.coTwoCategory === 2"
							:size="16"
							:color="getColorChevrons('coTwoCategory')"
							:fill="getColorChevrons('coTwoCategory')"
						/>
					</span>
				</div>
			</b-col>
			<b-col v-if="showCostCenterA" class="px-1 d-flex cursor-pointer" @click="handleClickHeader('costCenterA')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(12611, 'CC A') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.costCenterA === 0"
							:size="16"
							:color="getColorChevrons('costCenterA')"
							:fill="getColorChevrons('costCenterA')"
						/>
						<chevron-down
							v-if="sortFilter.costCenterA === 1"
							:size="16"
							:color="getColorChevrons('costCenterA')"
							:fill="getColorChevrons('costCenterA')"
						/>
						<chevron-up
							v-if="sortFilter.costCenterA === 2"
							:size="16"
							:color="getColorChevrons('costCenterA')"
							:fill="getColorChevrons('costCenterA')"
						/>
					</span>
				</div>
			</b-col>
			<b-col v-if="showCostCenterB" class="px-1 d-flex cursor-pointer" @click="handleClickHeader('costCenterB')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(12711, 'CC B') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.costCenterB === 0"
							:size="16"
							:color="getColorChevrons('costCenterB')"
							:fill="getColorChevrons('costCenterB')"
						/>
						<chevron-down
							v-if="sortFilter.costCenterB === 1"
							:size="16"
							:color="getColorChevrons('costCenterB')"
							:fill="getColorChevrons('costCenterB')"
						/>
						<chevron-up
							v-if="sortFilter.costCenterB === 2"
							:size="16"
							:color="getColorChevrons('costCenterB')"
							:fill="getColorChevrons('costCenterB')"
						/>
					</span>
				</div>
			</b-col>
			<b-col v-if="showCostCenterC" class="px-1 d-flex cursor-pointer" @click="handleClickHeader('costCenterC')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(12811, 'CC C') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.costCenterC === 0"
							:size="16"
							:color="getColorChevrons('costCenterC')"
							:fill="getColorChevrons('costCenterC')"
						/>
						<chevron-down
							v-if="sortFilter.costCenterC === 1"
							:size="16"
							:color="getColorChevrons('costCenterC')"
							:fill="getColorChevrons('costCenterC')"
						/>
						<chevron-up
							v-if="sortFilter.costCenterC === 2"
							:size="16"
							:color="getColorChevrons('costCenterC')"
							:fill="getColorChevrons('costCenterC')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="px-1 d-flex cursor-pointer" @click="handleClickHeader('appChoice')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(1282, 'Visible') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.appChoice === 0"
							:size="16"
							:color="getColorChevrons('appChoice')"
							:fill="getColorChevrons('appChoice')"
						/>
						<chevron-down
							v-if="sortFilter.appChoice === 1"
							:size="16"
							:color="getColorChevrons('appChoice')"
							:fill="getColorChevrons('appChoice')"
						/>
						<chevron-up v-if="sortFilter.appChoice === 2" :size="16" :color="getColorChevrons('appChoice')" :fill="getColorChevrons('appChoice')" />
					</span>
				</div>
			</b-col>
			<b-col class="px-1 d-flex cursor-pointer" @click="handleSortAMount('initialBudget')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(1213, 'Amount') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.initialBudget === 0"
							:size="16"
							:color="getColorChevrons('initialBudget')"
							:fill="getColorChevrons('initialBudget')"
						/>
						<chevron-down
							v-if="sortFilter.initialBudget === 1"
							:size="16"
							:color="getColorChevrons('initialBudget')"
							:fill="getColorChevrons('initialBudget')"
						/>
						<chevron-up
							v-if="sortFilter.initialBudget === 2"
							:size="16"
							:color="getColorChevrons('initialBudget')"
							:fill="getColorChevrons('initialBudget')"
						/>
					</span>
				</div>
			</b-col>
			<b-col class="px-1 d-flex cursor-pointer" @click="handleSortAMount('estimatedFinalCost')">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(1219, 'EFC') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.estimatedFinalCost === 0"
							:size="16"
							:color="getColorChevrons('estimatedFinalCost')"
							:fill="getColorChevrons('estimatedFinalCost')"
						/>
						<chevron-down
							v-if="sortFilter.estimatedFinalCost === 1"
							:size="16"
							:color="getColorChevrons('estimatedFinalCost')"
							:fill="getColorChevrons('estimatedFinalCost')"
						/>
						<chevron-up
							v-if="sortFilter.estimatedFinalCost === 2"
							:size="16"
							:color="getColorChevrons('estimatedFinalCost')"
							:fill="getColorChevrons('estimatedFinalCost')"
						/>
					</span>
				</div>
			</b-col>
			<b-col :cols="1" @click="handleClickHeader('departmentName')" class="px-1 d-flex cursor-pointer">
				<div class="w-75" style="font-size: 10px">{{ FormMSG(1216, 'Department') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter.departmentName === 0"
							:size="16"
							:color="getColorChevrons('departmentName')"
							:fill="getColorChevrons('departmentName')"
						/>
						<chevron-down
							v-if="sortFilter.departmentName === 1"
							:size="16"
							:color="getColorChevrons('departmentName')"
							:fill="getColorChevrons('departmentName')"
						/>
						<chevron-up
							v-if="sortFilter.departmentName === 2"
							:size="16"
							:color="getColorChevrons('departmentName')"
							:fill="getColorChevrons('departmentName')"
						/>
					</span>
				</div>
			</b-col>
			<b-col style="font-size: 10px" class="text-center px-1">
				{{ FormMSG(1229, 'Option') }}
			</b-col>
		</b-row>
		<div class="content-table-budgets">
			<loading :active.sync="setLoading" :is-full-page="true"></loading>
			<div v-if="budgets.length > 0 && !isLoading">
				<tree-node-budget
					:displayAllCategories="displayAllCategories"
					:showCostCenterA="showCostCenterA"
					:showCostCenterB="showCostCenterB"
					:showCostCenterC="showCostCenterC"
					:showchildrenOnSearch="showchildrenOnSearch"
					:depNumber="depNumber"
					v-for="(budget, index) in budgets"
					:key="index"
					:budgete="budget"
					@categorie-tree-view:addChild="addChild"
					@categorie-tree-view:SpecialUpdate="updatedContentTypeAndCoTwo"
					@categorie-tree-view:update="UpdateCostCenter"
					@categorie-tree-view:select="SelectedCategorie"
					@categorie-tree-view:delete="DeleteCategorie"
					@child-tree-view:delete="DeleteChild"
					@categorie-tree-view:clone="CloneCats"
					@categorie-tree-view:setAsDep="setAsDep"
					@categorie-tree-view:AmountUpdate="updateAmount"
					@categorie-tree-view:setAsFunc="setAsFunc"
				/>
			</div>
			<div v-else>
				<b-row v-if="!isLoading" style="border: 1px solid #dfdfdf !important; margin: 0">
					<b-col cols="12" class="text-center">
						{{ FormMSG(112, 'No category found') }}
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import TreeNodeBudget from './CategoryNode.vue';
import languageMessages from '@/mixins/languageMessages';
import { ChevronsUpDown, Search, X, ChevronDown, ChevronUp } from 'lucide-vue';
import { searchInTheTree } from '@/shared/utils';
import Loading from 'vue-loading-overlay';

export default {
	name: 'TableHierarchicalBudget',

	mixins: [languageMessages],

	props: {
		categorie: {
			type: Array,
			default: []
		},
		isLoading: { type: Boolean, default: false },
		showCostCenterA: { type: Boolean, default: false },
		showCostCenterB: { type: Boolean, default: false },
		showCostCenterC: { type: Boolean, default: false },
		depNumber: { type: Number, default: 0 },
		filter: { type: String, default: '' },
		displayAllCategories: { type: Boolean, default: false }
	},

	components: {
		TreeNodeBudget,
		ChevronsUpDown,
		ChevronDown,
		ChevronUp,
		Loading
	},

	data() {
		return {
			sortFilter: {
				costCenter: 0, // 0: none, 1: desc, 2: asc
				description: 0,
				coTwoCategory: 0,
				contentType: 0,
				appChoice: 0,
				initialBudget: 0,
				costCenterB: 0,
				costCenterA: 0,
				costCenterC: 0,
				departmentName: 0,
				estimatedFinalCost: 0
			},
			setLoading: false,
			showchildrenOnSearch: false,
			budgetsOriginal: [],
			filtre: '',
			selectedCategory: 0,
			selectedDepartment: 0,
			budgets: []
		};
	},

	watch: {
		filter: {
			handler(val) {
				this.handleChangeFilter(val);
			},
			immediate: true
		},
		categorie: {
			handler(val) {
				let Bud = [];
				if (val.length > 0) {
					if (this.depNumber == -1 || this.depNumber == 0 || this.displayAllCategories) {
						Bud = val;
					} else {
						if (val[0].children) {
							Bud = val[0].children;
						} else {
							Bud = [];
						}
					}
				}
				this.budgets = Bud;
				this.budgetsOriginal = [...Bud];
			},
			immediate: true
		}
	},

	methods: {
		setAsFunc(data) {
			this.$emit('categorie-tree-view:setAsFunc', data);
		},
		setAsDep(data) {
			this.$emit('categorie-tree-view:setAsDep', data);
		},
		updatedContentTypeAndCoTwo(data) {
			this.$emit('categorie-tree-view:SpecialUpdate', data);
		},
		prepareResultTree(data, stringIndex) {
			if (stringIndex === '') return;
			let stringIndexSplited = stringIndex.split('.');
			let currIndex = parseInt(stringIndexSplited[0]);
			this.showchildrenOnSearch = true;
			data[currIndex].is_selected = true;
			stringIndexSplited.shift();
			if (data[currIndex].children) {
				this.prepareResultTree(data[currIndex].children, stringIndexSplited.join('.'));
			}
		},
		resetSearch(element) {
			this.showchildrenOnSearch = false;
			for (let i = 0; i < element.length; i++) {
				let data = element[i];
				data.is_selected = false;
				if (data.children) {
					this.resetSearch(data.children);
				}
			}
		},
		handleChangeFilter(payload) {
			this.budgets = [];
			this.filtre = payload;
			let filter = payload.trim().toLowerCase();

			if (filter === '') {
				this.budgets = this.budgetsOriginal;
				let array = [];
				for (let i = 0; i < this.budgetsOriginal.length; i++) {
					let element = this.budgetsOriginal[i];
					if (element.children) {
						this.resetSearch(element.children);
					}
					element.is_selected = false;
					array.push(element);
				}
				this.budgetsOriginal = array;
			} else {
				let arr = [];
				for (let i = 0; i < this.budgetsOriginal.length; i++) {
					let element = this.budgetsOriginal[i];
					const resultSearch = searchInTheTree(element, 'label', filter);
					if (resultSearch !== '') {
						element.is_selected = true;
						this.prepareResultTree(element.children, resultSearch);
						arr.push(element);
					} else {
						if (element.label.toLowerCase().indexOf(filter) > -1) {
							element.is_selected = true;
							arr.push(element);
						}
					}
				}
				this.budgets = arr;
			}
		},
		updateAmount(data) {
			this.$emit('categorie-tree-view:AmountUpdate', data);
		},
		CloneCats(data) {
			this.$emit('categorie-tree-view:clone', data);
		},
		addChild(data) {
			this.$emit('categorie-tree-view:addChild', data);
		},
		UpdateCostCenter(data) {
			this.$emit('categorie-tree-view:update', data);
		},
		DeleteChild(data) {
			console.log(data);
		},
		SelectedCategorie(data) {
			this.$emit('categorie-tree-view:select', data);
		},
		DeleteCategorie(data) {
			this.$emit('categorie-tree-view:delete', data);
		},
		handleClickHeader(field) {
			this.setLoading = true;
			setTimeout(() => {
				this.addSortFilter(field);
				this.applySortFilter(field);
				this.setLoading = false;
			}, 150);
		},
		handleSortAMount(field) {
			this.setLoading = true;
			setTimeout(() => {
				this.addSortFilter(field);
				this.filterAmount(field);
				this.setLoading = false;
			}, 150);
		},
		addSortFilter(field) {
			let initValueSortFilter = {
				costCenter: 0,
				coTwoCategory: 0,
				description: 0,
				contentType: 0,
				appChoice: 0,
				initialBudget: 0,
				costCenterB: 0,
				costCenterA: 0,
				costCenterC: 0,
				departmentName: 0,
				estimatedFinalCost: 0
			};
			initValueSortFilter[field] = this.sortFilter[field];
			this.sortFilter = initValueSortFilter;

			if (this.sortFilter[field] === 2) {
				this.sortFilter[field] = 0;
			} else {
				this.sortFilter[field]++;
			}
		},
		filterAmount(field) {
			this.budgets = [];
			if (this.sortFilter[field] === 1) {
				this.budgets = this.budgetsOriginal.sort((a, b) => {
					let fa = a.data[`${field}`];
					let fb = b.data[`${field}`];
					return fa - fb;
				});
			} else if (this.sortFilter[field] === 2) {
				this.budgets = this.budgetsOriginal.sort((a, b) => {
					let fa = a.data[`${field}`];
					let fb = b.data[`${field}`];
					return fb - fa;
				});
			} else if (this.sortFilter[field] === 0) {
				this.budgets = [...this.budgetsOriginal];
			}
		},
		applySortFilter(field) {
			this.budgets = [];
			if (this.sortFilter[field] === 1) {
				this.budgets = this.budgetsOriginal.sort((a, b) => {
					let fa = a.data[`${field}`].toString().toLowerCase();
					let fb = b.data[`${field}`].toString().toLowerCase();
					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});
			} else if (this.sortFilter[field] === 2) {
				this.budgets = this.budgetsOriginal.sort((a, b) => {
					let fa = a.data[`${field}`].toString().toLowerCase();
					let fb = b.data[`${field}`].toString().toLowerCase();
					if (fa > fb) {
						return -1;
					}
					if (fa < fb) {
						return 1;
					}
					return 0;
				});
			} else if (this.sortFilter[field] === 0) {
				this.budgets = [...this.budgetsOriginal];
			}
		},
		getColorChevrons(field) {
			if (this.sortFilter[field] > 0) {
				return '#ffffff';
			}
			return '#4dbcba';
		}
	}
};
</script>

<style>
.header-table-budgets {
	background-color: #00a09c;
	border: 1px solid #ddd !important;
	margin-left: 0px;
	margin-right: 0;
}
.header-table-budgets > div {
	border-right: 1px solid #ddd !important;
	font-size: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: bolder;
	color: white;
}
.content-table-budgets .row {
	/* border: 1px solid #ddd !important; */
	margin-left: 0px;
	margin-right: 0;
}
.content-table-budgets .row:hover {
	background-color: #f3f5f7;
}
.content-table-budgets .row div {
	font-size: 12px;
	border-right: 1px solid #ddd !important;
	padding-top: 2px;
	padding-bottom: 2px;
}
</style>
